import { useEffect, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Environment, Center } from '@react-three/drei'
import { easing } from 'maath'
import * as THREE from 'three';
import { getTexture } from '../lib/textureCreation'
import Model from './Model'
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import { useSelector } from 'react-redux';
import { getLoadedDesigns } from '../redux/designsSlice';

export const CartItem = ({ position = [0, 0, 2.5], fov = 25, fullDesign }) => {
    
    const gender = fullDesign.selections?.gender?.toLowerCase()
    const type = fullDesign.selections?.item?.toLowerCase()?.replace(/ /g, '_')
    const garment = fullDesign.selections?.garment?.toLowerCase()
    const keeper = fullDesign.selections?.shirt?.toLowerCase().includes('goalkeeper')
    const inputs = fullDesign.selectedInputOptions
    const selectedDesign = fullDesign.designSelection

    const loadedDesigns = useSelector(getLoadedDesigns)
    const design = loadedDesigns[garment][selectedDesign]

    const [capture, setCapture] = useState(false)
    const [rotation, setRotation] = useState([0, 0, 0])
    const [currentPosition, setCurrentPosition] = useState(null)

    const [texture, setTexture] = useState(null)

    const startCapture = () => {
        setCapture(true)
    }
    const stopCapture = () =>{
        setCapture(false)
        setRotation([0, 0, 0])
    }
    const trackMouse = (event) => {
        if(capture){
            if(currentPosition){
                let x = event.screenY-currentPosition[0]
                let y = event.screenX-currentPosition[1]
                let mag = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
                mag = mag ? mag : 1
                setRotation([x/mag, y/mag, 0])
            }
            setCurrentPosition([event.screenY, event.screenX, 0])
        }
    }

    useEffect(()=>{

        async function setDesign() {
            const base64Texture = await getTexture(gender, type, garment, keeper, inputs, selectedDesign, design)

            if(base64Texture){
                const image = new Image();
                image.src = base64Texture;
                const newTexture = new THREE.Texture();
                newTexture.image = image;
                image.onload = function () {
                    newTexture.needsUpdate = true;
                };
                newTexture.flipY = false
                newTexture.encoding = THREE.sRGBEncoding
                setTexture(newTexture)
            }
        }
        setDesign()
        
    }, [gender, type, garment, keeper, inputs, selectedDesign, design])

    return (
        <div style={{width: '100%', height: '100%'}} onMouseDown={() => startCapture()} onMouseUp={() => stopCapture()} onMouseLeave={()=>stopCapture()} onMouseMove={(e) => trackMouse(e)}>
            <Canvas shadows camera={{ position, fov }} gl={{ preserveDrawingBuffer: true }} eventSource={document.getElementById('root')} eventPrefix="client">
                <ambientLight position={[0, 0, 0.4]} intensity={2}/>                
                <pointLight position={[0, 0.5, 0.5]} intensity={1} />
                <pointLight position={[0.5, 0, -0.2]} intensity={1} />
                <pointLight position={[-0.5, 0, -0.2]} intensity={1} />

                <Environment
                            preset={null} 
                            files={null}  
                            background
                            scene={new THREE.Scene()}  
                            children={(scene) => {
                            const pmremGenerator = new THREE.PMREMGenerator(scene);
                            const envTexture = pmremGenerator.fromScene(new RoomEnvironment()).texture;
                            scene.environment = envTexture;
                            scene.background = envTexture;
                        
                            }}
                        />
                
                <CameraRig deltaRotation={rotation}>
                    <Center>
                        <Model gender={gender} type={type} garment={garment} keeper={keeper} newTexture={texture}/>  
                    </Center>
                </CameraRig>
            </Canvas>
        </div>
    )
}

function CameraRig({deltaRotation, children }) {
    const group = useRef()
    useFrame((state, delta) => {
        
        easing.damp3(state.camera.position, [0, 0, 1.7], 0.25, delta)
        
        easing.dampE(group.current.rotation, [0.2, group.current.rotation.y + deltaRotation[1], group.current.rotation.z], 0.25, delta)
    })
    return <group ref={group}>{children}</group>
}


